import connection from "../connection";
import { print } from "graphql";
import incomeExpenseSetups from "../graphql/queries/invoiceGeneratorFeeTypes.graphql";
import createIncomeExpenseSetup from "../graphql/mutations/createIncomeExpenseSetup.graphql";
import deleteIncomeExpenseSetup from "../graphql/mutations/deleteIncomeExpenseSetup.graphql";

export default {
  /**
   * @param invoiceGenerator_id
   * @param transactionType_id
   * @param transactionType_direction
   * @returns {Array|Boolean}
   */
  readCollection(
    invoiceGenerator_id,
    transactionType_id,
    transactionType_direction
  ) {
    return connection.graph({
      query: print(incomeExpenseSetups),
      variables: {
        invoiceGenerator_id,
        transactionType_id,
        transactionType_direction,
      },
    });
  },
  /**
   * @param transactionType
   * @returns {Array|Boolean}
   */
  createCollection(transactionType) {
    let data = transactionType;
    data.transactionType = transactionType.transactionType.toString();

    return connection.graph({
      query: print(createIncomeExpenseSetup),
      variables: { input: { ...data } },
    });
  },
  /**
   * @param transactionTypeIriId
   * @returns {Array|Boolean}
   */
  deleteCollection(transactionTypeIriId) {
    return connection.graph({
      query: print(deleteIncomeExpenseSetup),
      variables: { input: { id: transactionTypeIriId } },
    });
  },
};
